import { PrinterPlugin } from './types';

interface CordovaPrinterPlugin {
  // Incomplete typing
  print(content?: string, options?: unknown, callback?: () => void): void;
}

export const createCordovaPrinterPlugin = (
  cordovaPrinterPlugin: CordovaPrinterPlugin
): PrinterPlugin => {
  return {
    isAvailable: () => !!cordovaPrinterPlugin,
    print: () =>
      new Promise((resolve) =>
        cordovaPrinterPlugin.print(undefined, undefined, resolve)
      )
  };
};
